import React, { useEffect } from "react"
import gsap from "gsap"

export default function ActOne() {
  useEffect(() => {
    gsap.set(".privacy-heading-letter", { autoAlpha: 1 })
    gsap.set(".privacy-subheading", { autoAlpha: 1 })

    gsap.from(".privacy-heading-letter", {
      autoAlpha: 0,
      duration: 1,
      ease: "power3.inOut",
      stagger: {
        // from: "center",
        from: "start",
        amount: 0.5,
      },
    })

    gsap.from(".privacy", {
      autoAlpha: 0,
      duration: 1,
      delay: 0.5,
      ease: "power3.inOut",
    })
  }, [])
  return (
    <div className="privacy-one">
      <div className="privacy-one__heading">
        <span className="privacy-heading-letter">P</span>
        <span className="privacy-heading-letter">r</span>
        <span className="privacy-heading-letter">i</span>
        <span className="privacy-heading-letter">v</span>
        <span className="privacy-heading-letter">a</span>
        <span className="privacy-heading-letter">c</span>
        <span className="privacy-heading-letter">y</span>
        <span className="privacy-heading-letter"> </span>
        <span className="privacy-heading-letter">P</span>
        <span className="privacy-heading-letter">o</span>
        <span className="privacy-heading-letter">l</span>
        <span className="privacy-heading-letter">i</span>
        <span className="privacy-heading-letter">c</span>
        <span className="privacy-heading-letter">y</span>
      </div>
    </div>
  )
}

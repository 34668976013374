import React, { useState } from "react"
import { Link } from "gatsby"

export default function Paragraph() {
  return (
    <div className={`pp`}>
      <div className="pp-h">Governing Law</div>
      <div className={`paragraph`}>
        <br />
        Any case identifying with Berserkers's site should be administered by
        the laws of the country of Cupertino, California Berserkers State
        without respect to its contention of law provisions. General Terms and
        Conditions applicable to Use of a Web Site.
        <br />
        <br />
      </div>
    </div>
  )
}

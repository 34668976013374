import React, { useState } from "react"
import { Link } from "gatsby"

export default function Paragraph() {
  return (
    <div className={`pp`}>
      <div className="pp-h">Use License</div>
      <div className={`paragraph`}>
        <br />
        Permission is allowed to temporarily download one duplicate of the
        materials (data or programming) on Berserkers's site for individual and
        non-business use only. This is the just a permit of license and not an
        exchange of title, and under this permit you may not:
        <br />
        modify or copy the materials;
        <br />
        use the materials for any commercial use , or for any public
        presentation (business or non-business);
        <br />
        attempt to decompile or rebuild any product or material contained on
        Berserkers's site;
        <br />
        remove any copyright or other restrictive documentations from the
        materials; or
        <br />
        transfer the materials to someone else or even "mirror" the materials on
        other server.
        <br />
        This permit might consequently be terminated if you disregard any of
        these confinements and may be ended by Berserkers whenever deemed. After
        permit termination or when your viewing permit is terminated, you must
        destroy any downloaded materials in your ownership whether in electronic
        or printed form.
        <br />
        <br />
      </div>
    </div>
  )
}

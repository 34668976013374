import "../styles/privacy.scss"
import React from "react"
import ActOne from "../components/Privacy/ActOne"
import Policy from "../components/Privacy/Policy"
import Header from "../components/Header"
import Footer from "../components/Footer"
export default function FAQ() {
  return (
    <div className="faq">
      <Header />
      <ActOne />
      <Policy />
      <Footer />
    </div>
  )
}

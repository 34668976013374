import React, { useState } from "react"
import { Link } from "gatsby"

export default function Paragraph() {
  return (
    <div className={`pp`}>
      <div className="pp-h">Amendments and Errata</div>
      <div className={`paragraph`}>
        <br />
        The materials showing up on Berserkers's site could incorporate
        typographical, or photographic mistakes. Berserkers does not warrant
        that any of the materials on its site are exact, finished, or current.
        Berserkers may roll out improvements to the materials contained on its
        site whenever without notification. Berserkers does not, then again,
        make any dedication to update the materials.
        <br />
        <br />
      </div>
    </div>
  )
}

import React, { useState } from "react"
import { Link } from "gatsby"

export default function Paragraph() {
  return (
    <div className={`pp  active`}>
      <div className="pp-h">Terms</div>
      <div className={`paragraph`}>
        <br />
        By accessing this web site, you are agreeing to be bound by these web
        site Terms and Conditions of Use, applicable laws and regulations and
        their compliance. If you disagree with any of the stated terms and
        conditions, you are prohibited from using or accessing this site. The
        materials contained in this site are secured by relevant copyright and
        trade mark law.
        <br />
        <br />
      </div>
    </div>
  )
}

import React, { useState } from "react"
import { Link } from "gatsby"

export default function Paragraph() {
  return (
    <div className={`pp`}>
      <div className="pp-h">Site Terms of Use Modifications</div>
      <div className={`paragraph`}>
        <br />
        In no occasion should Berserkers or its suppliers subject for any harms
        (counting, without constraint, harms for loss of information or benefit,
        or because of business interference,) emerging out of the utilization or
        powerlessness to utilize the materials on Berserkers's Internet webpage,
        regardless of the possibility that Berserkers or a Berserkers approved
        agent has been told orally or in written of the likelihood of such harm.
        Since a few purviews don't permit constraints on inferred guarantees, or
        impediments of obligation for weighty or coincidental harms, these
        confinements may not make a difference to you.
        <br />
        <br />
      </div>
    </div>
  )
}

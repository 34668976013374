import React, { useState } from "react"
import { Link } from "gatsby"

export default function Paragraph() {
  return (
    <div className={`pp `}>
      <div className="pp-h">Disclaimer</div>
      <div className={`paragraph`}>
        <br />
        The materials on Berserkers's site are given "as is". Berserkers makes
        no guarantees, communicated or suggested, and thus renounces and
        nullifies every single other warranties, including without impediment,
        inferred guarantees or states of merchantability, fitness for a specific
        reason, or non-encroachment of licensed property or other infringement
        of rights. Further, Berserkers does not warrant or make any
        representations concerning the precision, likely results, or unwavering
        quality of the utilization of the materials on its Internet site or
        generally identifying with such materials or on any destinations
        connected to this website.
        <br />
        <br />
      </div>
    </div>
  )
}

import React from "react"
import ParagraphOne from "./ParagraphOne"
import ParagraphTwo from "./ParagraphTwo"
import ParagraphThree from "./ParagraphThree"
import ParagraphFour from "./ParagraphFour"
import ParagraphFive from "./ParagraphFive"
import ParagraphSix from "./ParagraphSix"
import ParagraphSeven from "./ParagraphSeven"
import ParagraphEight from "./ParagraphEight"
import ParagraphNine from "./ParagraphNine"

export default function Policy() {
  return (
    <div className="questions">
      <ParagraphOne />
      <ParagraphTwo />
      <ParagraphThree />
      <ParagraphFour />
      <ParagraphFive />
      <ParagraphSix />
      <ParagraphSeven />
      <ParagraphEight />
      <ParagraphNine />
    </div>
  )
}

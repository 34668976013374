import React, { useState } from "react"
import { Link } from "gatsby"

export default function Paragraph() {
  return (
    <div className={`pp`}>
      <div className="pp-h">Links</div>
      <div className={`paragraph`}>
        <br />
        Berserkers has not checked on the majority of the websites or links
        connected to its website and is not in charge of the substance of any
        such connected webpage. The incorporation of any connection does not
        infer support by Berserkers of the site. Utilization of any such
        connected site is at the user's own risk.
        <br />
        <br />
      </div>
    </div>
  )
}
